import React from "react";
import FileSelect from "./Components/FileSelect";
import FileHandler from "./FileHandler";
import LinkValidator from "./LinkValidator";
import * as XLSX from "xlsx";
import * as filesaver from "file-saver";
import LoadingIndicator from "./Components/LoadingIndicator";
import DataTable from "./Components/DataTable";

class ClassChecker extends React.Component {
	constructor(props) {
		super(props);
		this.run = this.run.bind(this);
		this.exp = this.exp.bind(this);
		this.state = { loading: false, run: false };
		this.fileHandler = new FileHandler();
		this.res = null;
		this.testMode = false;
	}

	async run() {
		const lines = this.fileHandler.text;

		//Alerts the user if they have not uploaded a file
		if (lines == null) {
			alert(LinkValidator.errors[0]);
			return;
		}

		//Alerts the user if their file is too short
		if (lines.length < 3) {
			console.log(lines);
			alert(LinkValidator.errors[1]);
			return;
		}

		//Sets loading state to true to show the loading icon
		await this.setState((state) => {
			return { loading: true, run: false };
		});

		//Strips carrage returns from lines and removes empty lines
		for (var i = 0; i < lines.length; i++) {
			if (lines[i] == null || lines[i] == undefined || lines[i] == "\n\r") {
				lines.splice(i, 1);
				i--;
				continue;
			}

			if (lines[i][lines[i].length - 1] == "\r") {
				lines[i] = lines[i].substr(0, lines[i].length - 1);
			}
		}

		try {
			//Post call to the server api
			await fetch(
				!this.testMode
					? "https://tapi.crewlocal.com/acc/api/getclasses"
					: "http://localhost:3001/acc/api/getclasses",
				{
					method: "POST",
					mode: "cors",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ lines: lines }),
				}
			)
				//Resolves the result
				.then((res) => Promise.resolve(res))
				//Converts the result to json
				.then((res) => res.json())
				.then((result) => {
					//Alerts the user if there was an error with the server api
					if (LinkValidator.errors.includes(result.data)) {
						alert(result.data);
						return;
					}

					this.res = result;

					//Sets loading state to false to hide the loading icon and run to true to show the data table
					this.setState((state) => {
						return { loading: false, run: true };
					});

					alert("Finished.");
				});
		} catch (err) {
			//Alerts the user if there was an error connecting to the server
			alert("Could not connect to server - " + err);
			this.setState((state) => {
				return { loading: false, run: false };
			});
			return;
		}
	}

	exp() {
		if (!this.state.run) {
			alert("You must run the test before exporting");
			return;
		}

		var wb = XLSX.utils.book_new();

		wb.Props = {
			Title: "Button Class Info",
			Subject: "Iatse web button classes",
			Author: "Tumblewire",
		};

		wb.SheetNames.push("Sheet 1");

		var data = [["Button: ", "Classes: ", "URL: "]];

		for (var i = 0; i < this.res.data.labels.length; i++) {
			console.log(i);
			data.push([
				this.res.data.labels[i],
				this.res.data.classes[i],
				this.res.data.urls[i],
			]);
		}

		var sheet = XLSX.utils.aoa_to_sheet(data);

		wb.Sheets["Sheet 1"] = sheet;

		var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

		try {
			var buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
			var view = new Uint8Array(buf); //create uint8array as viewer
			for (var i = 0; i < wbout.length; i++)
				view[i] = wbout.charCodeAt(i) & 0xff; //convert to octet
		} catch (err) {
			alert("Octet Conversion Failed, Please Try Again - " + err);
			return;
		}

		try {
			filesaver.saveAs(
				new Blob([buf], { type: "application/octet-stream" }),
				this.getFileName()
			);
		} catch (err) {
			alert("Save to computer failed - " + err);
			return;
		}
	}

	getFileName() {
		var date = new Date();
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hours = date.getHours();
		var mins = date.getMinutes();
		var secs = date.getSeconds();

		return (
			"IatseButtonClasses-" +
			date.getFullYear().toString() +
			"-" +
			(month > 9 ? month.toString() : "0" + month.toString()) +
			"-" +
			(day > 9 ? day.toString() : "0" + day.toString()) +
			"-" +
			(hours > 9 ? hours.toString() : "0" + hours.toString()) +
			"-" +
			(mins > 9 ? mins.toString() : "0" + mins.toString()) +
			"-" +
			(secs > 9 ? secs.toString() : "0" + secs.toString()) +
			".xlsx"
		);
	}

	render() {
		const { loading, run } = this.state;

		document.title = "Authenticated Class Checker";

		return (
			<div>
				{/*Open file and Run Buttons*/}
				<div className="container-fluid">
					<div className="form-inline col-12 justify-content-center">
						<div className="d-flex justify-content-center">
							<FileSelect
								onFileHandle={this.fileHandler.loadTextFromFile}
								accept="text/plain"
								className="btn btn-info mr-3"
							/>
							<button className="btn btn-info ml-3" onClick={this.run}>
								{" "}
								Run{" "}
							</button>
						</div>
					</div>
				</div>
				{/*Loading wheel*/}
				{loading ? (
					<div className="container-fluid mt-4">
						<div className="col-12">
							<div className="d-flex justify-content-center">
								<LoadingIndicator />
							</div>
						</div>
					</div>
				) : (
					<div></div>
				)}
				{/*Data table and Export Button*/}
				{run ? (
					<div className="container-fluid">
						<div className="col-12">
							<div className="justify-content-center">
								<DataTable
									data={this.res.data}
									header="Button Data"
									titles={["Labels", "Classes", "Ids", "Urls"]}
								/>
								<button className="btn btn-info" onClick={this.exp}>
									{" "}
									Export{" "}
								</button>
							</div>
						</div>
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
	}
}

export default ClassChecker;
