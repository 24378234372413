import React from "react";
import Image from "../Resources/loading_wheel.png";

const LoadingIndicator = (props) => {
	return (
		<div>
			<img
				className="loading-indicator"
				src={Image}
				style={{
					width: "200px",
					height: "200px",
					animation: "rotating 2s linear",
					animationIterationCount: "infinite",
				}}
			/>
		</div>
	);
};

export default LoadingIndicator;
