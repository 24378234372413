import React from "react";
import * as XLSX from "xlsx";
import * as filesaver from "file-saver";

import LoadingIndicator from "./Components/LoadingIndicator";
import DataTable from "./Components/DataTable";
import FileSelect from "./Components/FileSelect";
import FileHandler from "./FileHandler";

class LinkValidator extends React.Component {
	static errors = [
		"Please Upload A File Before Running",
		"File is too short, file must be at least 3 lines!",
	];

	//Base Constructor and binding to class
	constructor(props) {
		super(props);
		this.state = { loading: false, run: false };
		this.run = this.run.bind(this);
		this.exp = this.exp.bind(this);
		this.getFileName = this.getFileName.bind(this);
		this.fileButton = null;
		this.res = null;
		this.fileHandler = new FileHandler();
	}

	//Called when the run button is pressed
	async run() {
		const lines = this.fileHandler.text;

		//Alerts the user if they have not uploaded a file
		if (lines == null) {
			alert(LinkValidator.errors[0]);
			return;
		}

		//Alerts the user if their file is too short
		if (lines.length <= 3) {
			alert(LinkValidator.errors[1]);
			return;
		}

		//Sets loading state to true to show the loading icon
		await this.setState((state) => {
			return { loading: true, run: false };
		});

		//Strips carrage returns from lines and removes empty lines
		for (var i = 0; i < lines.length; i++) {
			if (lines[i] == null || lines[i] == undefined || lines[i] == "\n\r") {
				lines.splice(i, 1);
				i--;
				continue;
			}

			if (lines[i][lines[i].length - 1] == "\r") {
				lines[i] = lines[i].substr(0, lines[i].length - 1);
			}
		}

		try {
			//Post call to the server api
			await fetch("https://tapi.crewlocal.com/alv/api/testiatse", {
				method: "POST",
				mode: "cors",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ lines: lines }),
			})
				//Resolves the result
				.then((res) => Promise.resolve(res))
				//Converts the result to json
				.then((res) => res.json())
				.then((result) => {
					//Alerts the user if there was an error with the server api
					if (LinkValidator.errors.includes(result.data)) {
						alert(result.data);
						return;
					}

					this.res = result;

					//Sets loading state to false to hide the loading icon and run to true to show the data table
					this.setState((state) => {
						return { loading: false, run: true };
					});

					alert("Finished.");
				});
		} catch (err) {
			//Alerts the user if there was an error connecting to the server
			alert("Could not connect to server - " + err);
			this.setState((state) => {
				return { loading: false, run: false };
			});
			return;
		}
	}

	//
	exp() {
		if (!this.state.run) {
			alert("You must run the test before exporting");
			return;
		}

		var wb = XLSX.utils.book_new();

		wb.Props = {
			Title: "Iatse Test Info",
			Subject: "Iatse web testing status",
			Author: "Tumblewire",
		};

		wb.SheetNames.push("Sheet 1");

		var data = [
			["URL: ", "Title: ", "Has Access: ", "User: " + this.res.username],
		];

		for (var i = 0; i < this.res.data.results.length; i++) {
			data.push([
				this.res.data.urls[i],
				this.res.data.titles[i],
				this.res.data.results[i],
			]);
		}

		var sheet = XLSX.utils.aoa_to_sheet(data);

		wb.Sheets["Sheet 1"] = sheet;

		var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

		try {
			var buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
			var view = new Uint8Array(buf); //create uint8array as viewer
			for (var i = 0; i < wbout.length; i++)
				view[i] = wbout.charCodeAt(i) & 0xff; //convert to octet
		} catch (err) {
			alert("Octet Conversion Failed, Please Try Again - " + err);
			return;
		}

		try {
			filesaver.saveAs(
				new Blob([buf], { type: "application/octet-stream" }),
				this.getFileName()
			);
		} catch (err) {
			alert("Save to computer failed - " + err);
			return;
		}
	}

	getFileName() {
		var date = new Date();
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hours = date.getHours();
		var mins = date.getMinutes();
		var secs = date.getSeconds();

		return (
			"IatseTechTest-" +
			date.getFullYear().toString() +
			"-" +
			(month > 9 ? month.toString() : "0" + month.toString()) +
			"-" +
			(day > 9 ? day.toString() : "0" + day.toString()) +
			"-" +
			(hours > 9 ? hours.toString() : "0" + hours.toString()) +
			"-" +
			(mins > 9 ? mins.toString() : "0" + mins.toString()) +
			"-" +
			(secs > 9 ? secs.toString() : "0" + secs.toString()) +
			".xlsx"
		);
	}

	//Function called to render the component
	render() {
		const { loading, run } = this.state;

		document.title = "Authenticated Link Validator";

		return (
			<div>
				<div className="container-fluid">
					<div className="col-12">
						<div className="d-flex justify-content-center">
							<h1 className="mt-4">Authenticated Link Validator</h1>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="col-12">
						<div className="d-flex justify-content-center">
							<div className="sub-title mb-3">
								Advanced Development Technologies By Tumblewire
							</div>
						</div>
					</div>
				</div>
				<hr style={{ borderTop: "4px solid #17a1b8" }} />
				{/*Explanation*/}
				<div className="container-fluid">
					<div className="col-12">
						<div className="d-flex justify-content-center text-center">
							<p>
								This application will test whether urls on{" "}
								<a
									href="https://live873.iatse873.com"
									className="badge badge-info"
								>
									https://live873.iatse873.com
								</a>{" "}
								are accesible by a given user. <br />
								To start upload a .txt file below with the username in the first
								line and the password in the second line <br />
								with all of the urls you want to test on lines after that. Then
								click the run button, if the loading icon <br />
								appears that means its working. Once its finished an alert
								saying "Finished." and a table showing the <br />
								result will appear, then you can click the Export button to
								export the results as .xlsx
							</p>
						</div>
					</div>
				</div>
				{/*Open file and Run Buttons*/}
				<div className="container-fluid">
					<div className="form-inline col-12 justify-content-center">
						<div className="d-flex justify-content-center">
							<FileSelect
								onFileHandle={this.fileHandler.loadTextFromFile}
								accept="text/plain"
								className="btn btn-info mr-3"
							/>
							<button className="btn btn-info ml-3" onClick={this.run}>
								{" "}
								Run{" "}
							</button>
						</div>
					</div>
				</div>
				{/*Loading wheel*/}
				{loading ? (
					<div className="container-fluid mt-4">
						<div className="col-12">
							<div className="d-flex justify-content-center">
								<LoadingIndicator />
							</div>
						</div>
					</div>
				) : (
					<div></div>
				)}
				{/*Data table and Export Button*/}
				{run ? (
					<div className="container-fluid">
						<div className="col-12">
							<div className="justify-content-center">
								<DataTable
									data={this.res.data}
									header={`Username: ${this.props.data.username}`}
									titles={["Has Access", "Title", "URL"]}
								/>
								<button className="btn btn-info" onClick={this.exp}>
									{" "}
									Export{" "}
								</button>
							</div>
						</div>
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
	}
}

export default LinkValidator;
