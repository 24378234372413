import {
	BrowserRouter as Router,
	Routes as Switch,
	Route,
} from "react-router-dom";
import ClassChecker from "./ClassChecker";
import LinkValidator from "./LinkValidator";
import VariableFinder from "./VariableFinder";

export default function App() {
	return (
		<main>
			<Router>
				<Switch>
					<Route path="/alv" element={<LinkValidator />} />
					<Route path="/acc" element={<ClassChecker />} />
					<Route path="/avf" element={<VariableFinder />} />
				</Switch>
			</Router>
		</main>
	);
}
