import React from "react";

class FileSelect extends React.Component {
	//Constructor
	constructor(props) {
		super(props);
		this.loadFile = props.onFileHandle;
		this.state = { fileName: "Choose a file..." };
	}

	onClick(e) {
		const file = e.target.files[0];

		if (file == undefined) return;

		this.setState((state) => {
			return { fileName: file.name };
		});

		this.loadFile(file);
	}

	render() {
		const { fileName } = this.state;

		return (
			<button
				className={this.props.className != null ? this.props.className : ""}
				onClick={() => {
					document.getElementById("f-upload").click();
				}}
			>
				<span>{fileName}</span>
				<input
					type="file"
					class="hidden"
					id="f-upload"
					onChange={(e) => this.onClick(e)}
					accept={this.props.accept != null ? this.props.accept : ""}
				/>
			</button>
		);
	}
}

export default FileSelect;
