import React from "react";

class DataTable extends React.Component {
	constructor(props) {
		super(props);
		this.processData = this.processData.bind(this);
		this.processTitles = this.processTitles.bind(this);
	}

	getRow(i, keys) {
		let output = [];

		for (var j = 0; j < keys.length; j++) {
			var x = this.props.data[keys[j]];

			if (x == undefined) {
				console.log(keys[j] + " is null");
			}

			output.push(
				<td>{x[i] == undefined ? "Not Available." : x[i].toString()}</td>
			);
		}

		return output;
	}

	processData() {
		let output = [];
		let keys = Object.keys(this.props.data);

		for (var i = 0; i < this.props.data[keys[0]].length; i++) {
			output.push(<tr key={i}>{this.getRow(i, keys)}</tr>);
		}

		return output;
	}

	processTitles() {
		let output = [];

		for (var i = 0; i < this.props.titles.length; i++) {
			output.push(
				<th>
					<th>{this.props.titles[i]}</th>
				</th>
			);
		}

		return output;
	}

	render() {
		return (
			<div id="data-table" style={{ maxHeight: "50vh", overflow: "auto" }}>
				<h5>{this.props.data.header}</h5>
				<table className="table table-striped">
					<thead className="thead-dark">
						<tr>{this.processTitles()}</tr>
					</thead>
					<tbody>{this.processData()}</tbody>
				</table>
			</div>
		);
	}
}

export default DataTable;
