class FileHandler {
	constructor() {
		this.handleTextFileOnEnd = this.handleTextFileOnEnd.bind(this);
		this.loadTextFromFile = this.loadTextFromFile.bind(this);
		this.text = null;
	}

	//Handles file when file has finished parsing
	handleTextFileOnEnd = async (e) => {
		this.text = e.target.result.split("\n");
	};

	//Called when file is uploaded
	async loadTextFromFile(file) {
		console.log("Loading file " + file.name + "...");

		if (file == null) return;

		var reader = new FileReader();
		reader.onloadend = this.handleTextFileOnEnd;

		try {
			//Converts file into text
			reader.readAsText(file);
		} catch (err) {
			//Alerts user if there was an error loading the file
			alert("Could not read file - " + err);
			return;
		}
	}
}

export default FileHandler;
